.text-regular { font-weight: 400 }
.text-medium { font-weight: 600 }
.text-bold { font-weight: 700 }

.font-14 { font-size: 14px }
.font-roboto { font-family: Roboto, Helvetica, sans-serif !important; }

.user-permission-modal {
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
}

.more-about-permissions-modal {
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    overflow: hidden;
    position: relative;
}


.user-permission-modal button.learn-more-about-permission {
    padding: 0.6em 1.5em;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    background-color: #000;
    color: #fff;
    height: 39px;
    cursor: pointer;
    align-self: flex-end;
}

.user-permission-modal .row-label {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0;
}

.user-permission-modal .input-fields {
    border: 1px solid #000;
    border-radius: 12px;
    font-size: 14px;
    margin: 13px 0 0 0;
    padding: 0 0 0 30px;
    text-align: left;
}

.user-permission-modal .heading {
    font-size: 40px;
    font-weight: 700;
}

.user-permission-modal .flex-row {
    margin: 0.8em 0 1em 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.user-permission-modal .item-row {
    margin: 1em 0 1em 0;
}

.user-permission-modal button.disabled {
    pointer-events: none;
    cursor: none;
    opacity: 0.6 !important;
    border: none;
}

.permission-modal-form {
    position: relative;
}

.custom-scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    border-right: none;
    border-left: none;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background-color: #174A84;
    border-radius: 6px;
}

.custom-scroll::-webkit-scrollbar-track-piece:start {
    background-color: #D1DBE6;
    margin-top: 20px;
    border-radius: 6px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scroll::-webkit-scrollbar-track-piece:end {
    background-color: #D1DBE6;
    border-radius: 6px;
}

.more-about-permissions-modal .content {
    position:relative;
    overflow-y: scroll;
    margin: 50px 0 0 0;
    max-height: 1000px;
}
.more-about-permissions-modal .heading{
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    margin-right: 192px;
}


.more-about-permissions-modal ul.perm-list {
    padding: 0;
    list-style: none;
    margin: 20px 0;
}

.more-about-permissions-modal ul.perm-list li {
    font-size: 0.9em;
}

.more-about-permissions-modal ul.perm-list li .list-item {
    text-align: left;
    color: #000;
    font-family: Roboto, Helvetica, sans-serif;
    margin: 0;
}

.permission-modal-form .permissions-checkbox {
    position: relative;
}

.permission-modal-form .permissions-checkbox input[type="checkbox"] {
    display: none;
}

.permission-modal-form .permissions-checkbox label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 1.5em;
    padding-left: 2em;
}

.permission-modal-form .permissions-checkbox label:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1.2em;
    height: 1.2em;
    border: 1px solid #A5C9FF;
    background-color: #fff;
    border-radius: 3px;
}

.permission-modal-form .permissions-checkbox input[type="checkbox"]:checked + label:before {
    content: "\2713"; /* Unicode checkmark symbol */
    text-align: center;
    line-height: 20px;
    color: #fff;
    background-color: #007bff;
}



/* old version below */
.row {
    margin: 1em 0 1.9em 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.row .field {
    font-family: Roboto, Helvetica, sans-serif
}

.row .field label {
    font-size: 14px;
    font-weight: 700;
    margin-right: 1em;
}
.row .description {
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    color: #808080;
}

.row select {
    height: 2.9em;
    width: 15em;
    border-radius: 7px;
    background: #FFFFFF 0 0 no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #000;
    padding: 0.3em 1.1em;
    color: #000000;
    font-size: 20px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='45' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;
}

.wrapper {
    position: relative
}

/* used to cover the table border that appears on the rounded borders of the wrapper when scrolled */
.filler {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    height: 30px;
    width: 100%;
    background-color: #EAEDF4;
}   

.scrollable-table {
    position: relative;
    overflow-y: auto;
    overflow-x: scroll;
    width: 100%;
    max-width: 94.5rem;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.scrollable-table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background: white;
    border: 1px solid #8291B2;
    border-top: none;
    border-radius: 0 0 7px 7px;
    border-bottom: none;
    z-index: -1;
}

.scrollable-table table tr.selected {
    background-color: #EDF4FF;
}
.scrollable-table table tr.selected td.change-color-on-select {
    color: #174A84;
}

.scrollable-table table td, .scrollable-table table th {
    border-color: #8291B2;
    font-family: Roboto, Helvetica, sans-serif;
    white-space: nowrap;
}

.scrollable-table thead th {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 1em;
    font-size: 0.8em;
    font-weight: 700;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #8291B2;
}

.scrollable-table table td {
    padding: 10px 1em;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid;
    border-right: 1px solid;
    border-color: #8291B2;
}

.scrollable-table thead th:last-child {
    border-right: none;
}

.scrollable-table table tr td:last-child {
    border-right: none;
}

.scrollable-table table tr:last-child td:first-child {
    border-bottom-left-radius: 7px;
}

.scrollable-table table tr:last-child td:last-child {
    border-bottom-right-radius: 7px;
}

.scrollable-table::-webkit-scrollbar {
    display: block;
    width: 8px;
    height: 8px;
}

.scrollable-table::-webkit-scrollbar-track {
    background: transparent;
}

.scrollable-table::-webkit-scrollbar-thumb {
    background-color: #174A84;
    border-radius: 6px;
    border-right: none;
    border-left: none;
}

.scrollable-table::-webkit-scrollbar-track-piece:end {
    background-color: #D1DBE6;
    border-radius: 6px;
    margin-bottom: 30px;
    margin-right: 30px;
}

.scrollable-table::-webkit-scrollbar-track-piece:start {
    background-color: #D1DBE6;
    border-radius: 6px;
}

.user-lists-table img.delete-icon {
    color: #2e3036;
    cursor: pointer;
}


button.employee-list-table-btn {
    min-width: 100px;
    width: 100%;
    padding: 0.6em 0.7em;
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 600;
    border-radius: 7px;
    border: none;
    box-shadow: none;
    cursor: pointer;
}

hr {
    background-color:#D8D8D8;
    height: 1px;
    border: none;
}

.more-about-permissions-modal .role-container {
    margin: 0 0 20px 0;
}

.more-about-permissions-modal .description {
    color:#000000;
    font-size: 0.9em;
    font-weight: 400;
    margin: 1.5em 0;
    max-width: 90%;
}

.field label {
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    margin: 0 0 15px 0;
}

.check-field {
    margin: 0 0 1em 0;
}

.radio-field {
    margin: 3px 0;
}
.check-field label, .radio-field label {
    margin-left: 5px;
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
}

.check-field .sub-text {
    font-size: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    font-weight: 400;
    margin: 0 0 0 1.6rem;
    max-width: 25em;
}

.grid {
    display: grid;
}

.grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
}
.grid-col-3 {
    grid-template-columns: repeat(3, 1fr);
}






