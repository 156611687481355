.sidebar {
    margin-top: 0;
    height: 100%;
    z-index: 10;
    width: 270px;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #404df3;
    box-sizing: border-box;
}

.sidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.sidebar::-webkit-scrollbar
{
	width: 5px;
	background-color: #736565;
}

.sidebar::-webkit-scrollbar-thumb
{
	background-color: rgba(156, 156, 156, 0.2);
}

.container-test {
    max-width: 270px;
    position: relative;
    padding: 40px 0;
    min-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.greetings {
    width: 270px;
    height: 52px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 40px 0;
}

.nav-menu-text-wrapper {
    height: 20px;
    margin: 0 27px 5px 27px;
    padding: 8px 0;
}
.nav-menu-text {
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 15px;
    color: #fff;
    opacity: 0.6;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    width: 100px;
}

.greetings .text {
    color: #fff;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.footer-text {
    margin: 0 0 60px 27px;
}

.footer-text a {
    text-decoration: none;
    margin: 0 0 3px 0;
    color: #fff;
    font-family: Roboto, Helvetica, sans-serif;
    font-size: 13px;
    display: block;
}


@media screen and (max-width: 1280px) {
    .sidebar {
        width: 85px;
        transition: all 0.2s ease-in-out;
    }

    .sidebar .greetings {
        width: 75px;
    }

    .sidebar:hover {
        width: 270px;
    }

    .sidebar:hover div.greetings > div {
        margin-right: 20px;
    }

    .sidebar:hover .greetings {
        width: 270px;
    }
}
